.my-qrcode{
    display: flex;
    justify-content:center;
    flex-direction: column;
    align-items: center;
}
.username{
    color: rgb(49, 49, 255);
    margin-top:10px;
}
