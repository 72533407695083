@media(max-width:1400px)
{
  .avail h5 span {
    font-size: 33px;
}
}

@media only screen and (max-width: 1199px) {
  .banner-caption h1 {
    font-size: 50px;
  }
  .app-showcase {
    padding: 0px;
  }
}
@media only screen and (max-width: 991px) {
  .navbar-collapse {
    padding: 0;
  }
  .navbar-collapse {
    position: absolute;
    top: 63px;
    left: 0;
    right: 0;
    padding: 16px 0px;
    background: #f5f5f5;
  }
  .header .navbar-nav .nav-link + .nav-link {
    margin-left: 0;
  }
  .navbar-nav {
    margin-right: 0;
  }
  .navbar-light .navbar-toggler:focus {
    box-shadow: none;
  }
  .navbar-nav .nav-link {
    padding: 9px 15px !important;
  }
  .banner-caption h1 {
    font-size: 38px;
  }
  .banner-caption p {
    line-height: 24px;
  }
  .navbar-toggler {
    background: white;
    padding: 3px 6px;
    margin-right: 16px;
  }

  .navbar-toggler-icon {
    background-size: 22px;
  }
  .feature-img img {
    max-height: 300px;
  }
  .section-title {
    font-size: 30px;
  }
  .feature-points {
    padding-left: 0;
  }
  .feature-points li {
    margin-bottom: 30px;
    padding-left: 18px;
  }
  .faq-sec img {
    max-height: 310px;
  }
  .feature-points li:before {
    font-size: 18px;
    height: 42px;
    width: 42px;
  }
  .feature-points ul:before {
    left: 0;
  }
  .feature-points li h5 {
    font-size: 15px;
    line-height: 1.2;
  }

  .feature-points li p {
    font-size: 13px;
  }
  .feature-img {
    padding: 0;
    width: 100%;
    text-align: center;
    padding-bottom: 24px;
  }
  .download-link a img {
    width: 130px;
    height: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .aboutus-sec .counter-img {
    display: none;
  }
  .banner-caption h1 {
    font-size: 30px;
  }
  .watermark {
    font-size: 28px;
    top: 24px;
  }
  .banner-caption h1 span {
    font-size: 18px;
  }
  .navbar-brand img {
    max-width: 175px;
  }
  .banner-caption p {
    font-size: 13px;
    line-height: 1.3;
    margin-bottom: 0;
  }
  .banner-caption h1 small {
    font-size: 16px;
  }
  .download-link a {
    display: inline-block;
  }
  .download-link a img {
    max-width: 100px;
    height: 32px;
  }
  .banner-caption {
    padding-right: 15px;
  }
  .banner-part {
    padding-top: 137px;
    padding-bottom: 0;
    padding-bottom: 10px;
  }
  .work-captuch figure img {
    max-width: initial;
  }
  .watermark {
    font-size: 38px;
    top: 13px;
    right: 20px;
  }
  .crytech-feature {
    padding: 28px 0 24px 0;
  }

  /* murli-csss */
  .about-content p {
    margin-bottom: 17px;
  }
  .footer-content {
    padding: 20px 0 20px 0;
  }
  .subscribe-title h3 strong {
    font-size: 25px;
  }
  .subscribe-form {
    height: 50px;
    float: none;
    margin: 0 auto;
  }
  .footer-about {
    padding-right: 0px;
    margin-bottom: 23px;
  }
  .app-contact .row {
    flex-direction: column-reverse;
  }
  .subscribe-title {
    text-align: center;
    margin-bottom: 19px;
  }
  .contact-form-outer .btn {
    height: 50px;
    margin: 11px 0;
    font-size: 16px;
  }
  .contact-form-outer .download-link {
    margin-bottom: 22px;
  }
  .faq-sec .accordion {
    margin-bottom: 70px;
  }
}

@media screen and (max-width: 575px) {
  .banner-caption {
    flex: 1;
    max-width: 100%;
  }

  

  .watermark {
    font-size: 28px;
    position: initial;
    text-align: center;
    display: block;
    margin-top: 11px;
  }
  .work-captuch figure img {
    max-width: 100%;
  }
  .work-captuch figure {
    text-align: center;
  }
  .work-captuch {
    margin-bottom: 23px;
  }
  .section-title {
    font-size: 20px;
  }
  .navbar-brand img {
    max-width: 52px;
  }
  .navbar-toggler {
    margin-right: 10px;
  }
  .navbar > .container {
    flex-wrap: nowrap;
  }
  .banner-part {
    padding-top: 90px;
  }
  .navbar-toggler-icon {
    background-size: 20px;
    width: 30px;
    height: 26px;
  }
  .download-link strong {
    margin: 15px 0 10px 0;
  }
  .faq-sec .accordion {
    margin-bottom: 30px;
  }
  .header .nav-link {
    padding: 0;
    font-size: 13px;
    font-weight: 400;
  }
  .header .nav-link.btn {
    font-size: 13px;
    font-weight: 400;
    padding: 6px;
  }
  .banner-caption {
    padding: 0 0 20px 0;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .feature-img {
    padding: 0;
  }
  .banner-main-img {
    flex: 0 0 60%;
    max-width: 60%;
    margin: auto;
  }

  .faq-sec .accordion .accordion-button.collapsed {
    font-size: 15px;
    padding: 11px 12px 14px 19px;
  }
  .faq-sec .accordion-body p {
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 11px;
  }
  .faq-sec .accordion-body {
    padding: 16px 11px 23px 11px;
  }
  .copyright {
    padding: 15px 0;
  }

  .footer-content h5 {
    font-size: 15px;
  }
  .footer-sociallink a {
    width: 28px;
    height: 28px;
    margin: 1px;
    font-size: 14px;
  }
  .feature-points li:before {
    font-size: 15px;
    height: 30px;
    width: 30px;
    outline: 5px double #6936ff;
    left: -30px;
  }
  .feature-points ul:before {
    left: 17px;
    height: 90%;
    top: 14px;
  }
  .banner-content-row {
    display: block;
    text-align: center;
  }
  .banner-caption h1 {
    font-size: 24px;
  }
  .feature-points ul {
    margin-left: 0;
    margin-top: 30px;
  }
}
@media only screen and (max-width: 479px) {
  .navbar-brand {
    margin-right: 10px;
  }
  .header .nav-link.btn {
    font-size: 12px;
  }
}
