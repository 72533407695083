@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800&display=swap');

html,
body,
#root,
.App,
.main_wrap {
  height: 100%;
}

body {
  font-family: 'Rubik', sans-serif;
  background: linear-gradient(86deg, #6236ff, #8e49fe);
  font-size: 14px;
  color: #000;
}

.btn-sm {
  background-color: #6436ff;
  color: #fff;
  font-size: 14px;
}

.copy_tag:hover,
.btn_theme:hover,
.btn-sm:hover {
  background-color: #3e0fd7 !important;
  color: #fff;
}

.btn_theme {
  background-color: #6436ff;
  color: #fff;
  font-size: 18px;
  padding: 11px 35px;
  border: 0;
}

.theme-border {
  border: 1px solid #6436ff;
}

.fs-6 {
  font-size: 14px !important;
  line-height: 16px;
}

.fs-5 {
  font-size: 16px !important;
  line-height: 18px;
}

.main_wrap {
  padding: 0 100px;
  height: calc(100vh - 54px);
  position: relative;
}

.breadcrumb {
  margin: 0;
}

.breadcrumb-item.active,
.breadcrumb-item a,
.breadcrumb-item {
  color: #fff;
  text-decoration: none;
}

.form-check-input[type='radio'] {
  width: 18px;
  height: 18px;
  border: 2px solid #808080;
  position: relative;
  top: -3px;
}

.form-check-input:checked[type='radio'] {
  background-color: #fff;
  border-color: #6436ff;
  background-image: url('../../../public/images/radio.svg');
  background-size: 12px;
}

.tabs_theme .nav-tabs {
  border-color: #d4d4d4;
}

.verify_modal .modal-content {
  min-width: 580px;
  z-index: 10;
}

/* .page-link {
    position: relative;
}

.page-link--active {
    background: #5ae4dd;
} */

.page-link:hover {
  transition: 0.3s;
  /* border-color: white; */
  /* background: white; */
}

.page-link:hover:before {
  top: -48px;
  display: block;
}

.page-link:hover:after {
  top: -18px;
  display: block;
}

.page-link:before {
  position: absolute;
  top: -40px;
  left: -27px;
  width: 100px;
  text-align: center;
  background: rgb(45, 45, 45);
  color: #f1eff8;
  font-size: 14px;
  padding: 8px 0;
  border-radius: 3px;
  content: attr(aria-label);
  display: none;
  transition: 0.3s;
  z-index: 2;
}
/* .page-link:after {
    position: absolute;
    width: 0;
    height: 0;
    top: -10px;
    left: -2px;
    border-top: 6px solid white;
    border-right: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 6px solid transparent;
    content: "";
    opacity: 0;
    transition: 0.3s;
  } */

.verify_modal .h4 {
  font-size: 22px;
}

.verify_modal h3 {
  font-size: 18px;
  margin-top: 50px;
  margin-bottom: 20px;
}

.verify_modal .btn_theme {
  padding: 10px 72px;
  margin-bottom: 50px;
}

.verify_modal .modal-header .btn-close {
  background: #fff url('../images/close-circle.svg') no-repeat;
  background-position: center;
  opacity: 1;
}

.verify_modal .modal-header .btn-close:focus {
  box-shadow: none;
}

.request_type {
  background-color: #f6f5fb;
}

.request_type form {
  padding: 60px 100px 60px 39px;
}

.request_type .form-control {
  padding: 12px;
}

.request_type .form-label {
  width: 100%;
}

.request_type .form-select {
  padding: 12px;
}

.get_loan h6 {
  font-size: 35px;
  color: #7d36ff;
}

.get_loan h4 span {
  font-size: 15px;
  font-weight: 400;
}

.track_modal .btn_theme {
  width: 100%;
}

.track_modal p {
  max-width: 456px;
}

.tabs_theme .nav-link {
  border: none;
  border-bottom: 3px solid transparent;
  padding: 0 5px 15px 5px;
  margin: 0 24px;
  font-size: 18px;
  color: #000000;
  font-weight: 500;
}

.tabs_theme .nav-item:first-child .nav-link {
  margin-left: 0;
}

.tabs_theme .nav-link.active,
.tabs_theme .nav-link:hover {
  color: #6436ff;
  border-bottom-color: #6436ff;
  background-color: transparent;
}

.tabs_theme td {
  padding: 20px;
  font-size: 18px;
}

/* .table-striped>tbody>tr:nth-of-type(odd)>* {
    background-color: #f6f5fb;
} */

.tabs_theme th {
  background: transparent;
  padding: 20px;
  font-weight: normal;
  font-size: 16px;
}

.left_form_wrap {
  height: 100%;
  background: #fff;
  padding: 34px;
}

.bg-light-gray {
  padding: 0;
  background: linear-gradient(181deg, #f6f5fb, #fff);
}

.right_inner_wrap {
  padding: 32px 32px 32px 10px;
}

.theme_tabs_content .nav-link {
  border-width: 1px;
}

.midium_title h3 {
  font-size: 18px;
}

.midium_title a {
  font-size: 14px;
}

.midium_title {
  padding: 15px 0;
}

.promo_card {
  border: 1px solid #e5e5e5;
  padding: 4px 6px;
  border-radius: 10px;
  margin-bottom: 15px;
  display: flex;
  align-items: flex-start;
}

.promo_card figure {
  margin: 0;
  flex: 0 0 68px;
  max-width: 68px;
  height: 68px;
  border-radius: 6px;
  overflow: hidden;
}

.promo_card figcaption {
  flex: 1;
  padding: 5px 72px 10px 10px;
  font-size: 14px;
  color: #000;
  position: relative;
}

.promo_card figcaption h4 {
  font-size: 14px;
  color: #000000;
  margin: 0;
}

.promo_card figcaption p {
  margin: 0;
  font-size: 14px;
}

.promo_code {
  color: #6236ff;
  word-break: break-all;
}

.promo_code strong {
  color: #000;
}

.copy_tag {
  background: #6236ff;
  position: absolute;
  text-decoration: none;
  padding: 6px 15px;
  color: #fff;
  border-radius: 5px;
  bottom: 8px;
  right: 6px;
}

.promo_card figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.promo_value_list figure {
  flex: 0 0 50px;
  max-width: 50px;
  height: 50px;
  border-radius: 100px;
  overflow: hidden;
}

.promo_value_list figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.promo_value_list figcaption {
  flex: 1;
  justify-content: space-between;
  padding-left: 20px;
}

.promo_value_list figcaption .promo-small-title {
  text-decoration: none;
  color: #000;
  font-weight: 500;
  font-size: 18px;
}

.promo_value_list {
  border: 1px solid #e6e6e6;
  padding: 24px 20px;
  background: #fff;
  margin-bottom: 10px;
}

.promo_value_list strong {
  padding-right: 15px;
  font-size: 18px;
}

.promo_value_list:nth-child(even) {
  background: #f5f2fc;
}

.left_form_wrap .form-check {
  font-size: 18px;
}

.gas_provider_list {
  padding: 24px;
  border: 1px solid #e6e6e6;
}

.gas_provider_list li a h5 {
  font-size: 18px;
  color: #000;
  margin: 0;
  font-weight: normal;
}

.gas_provider_list li a {
  text-decoration: none;
  text-align: center;
  padding: 10px;
  display: inline-block;
  width: 100%;
}

.gas_provider_list li {
  flex: 0 0 16.666%;
  max-width: 16.666%;
  margin-bottom: 10px;
}

.main_panel {
  padding: 35px;
  border-radius: 27px;
  min-height: calc(100% - 128px);
}

.forgot_panel {
  padding: 35px;
  border-radius: 27px;
}

.page_heading h2 {
  font-size: 22px;
  font-weight: 500;
}

.personal_info_form {
  max-width: 1050px;
}

.custom_check {
  display: inline-block;
  position: relative;
  cursor: pointer;
  line-height: 22px;
  padding-left: 30px;
}

.custom_check input {
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  margin: 0px 0px;
  z-index: 2;
}

.custom_check .check_indicator {
  height: 22px;
  width: 22px;
  position: absolute;
  top: 0px;
  left: 0px;
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 4px;
}

.custom_check input:checked + .check_indicator:before {
  width: 7px;
  height: 12px;
  position: absolute;
  left: 6px;
  top: 2px;
  border: solid #6436ff;
  border-width: 0px 2px 2px 0px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  content: '';
}

.footer ul li a {
  color: #fff;
  text-decoration: none;
  padding: 0 15px;
}

.breadcrumb-item + .breadcrumb-item::before {
  color: #fff;
  content: '>';
}

.notification span {
  width: 9px;
  height: 9px;
  top: 0;
  right: 0;
}

.language .dropdown-toggle::after,
.header_profile .dropdown-toggle::after {
  display: none;
}

.footer ul li a:hover {
  color: #000;
}

.register .nav-tabs {
  border-bottom: 1px solid #f1f1f1;
}

.register .nav-tabs .nav-link {
  font-size: 18px;
  font-weight: 600;
  display: block;
  width: 100%;
  padding: 25px 10px;
  color: #a5a5a5;
  border-radius: 0;
  border: 0;
}

.register .nav-tabs .nav-item {
  width: 50%;
}

.register .nav-tabs .nav-link.active {
  background-color: #f1eff8;
  color: #6339fe;
}

.register form {
  max-width: 500px;
  margin: 0 auto;
  padding: 30px;
}

.form-floating .verify {
  top: 15px;
  right: 15px;
}

.link {
  color: #7d36ff;
  text-decoration: none;
}

.btn_outline {
  background: none;
  border: 1px solid #3e0fd7;
  color: #3e0fd7;
}

.btn_outline:hover {
  background: #3e0fd7;
  color: #fff;
}

.home_main ul li {
  padding: 0 15px;
  max-width: 155px;
  margin: 0 0 20px;
  text-align: center;
}

.home_main ul li a {
  display: block;
  text-decoration: none;
  line-height: 20px;
  font-size: 18px;
}

.home_main ul li a figure {
  overflow: hidden;
  border-radius: 100%;
}

.home_main ul li a figure img {
  width: 123px;
  height: 123px;
  border-radius: 100%;
  transition: 0.4s ease all;
}

.home_main ul li a:hover span {
  color: #3e0fd7 !important;
}

.home_main ul li a:hover img {
  transform: scale(1.2);
}

.avail {
  background: url(../images/avail_shape.png) no-repeat right top #63c3eb;
  border-radius: 8px;
  padding: 40px 30px;
  max-width: 408px;
  width: 100%;
}

.avail h5 span {
  font-size: 45px;
  word-break: break-all;
  font-weight: 600;
}

.wallet_amount .custom_radio {
  width: 100%;
  display: block;
  cursor: pointer;
  line-height: 22px;
  position: relative;
  height: 40px;
  max-width: 80px;
  margin-right: 10px;
}

.wallet_amount .custom_radio input {
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  margin: 0px 0px;
  z-index: 2;
}

.wallet_amount .custom_radio .radio_indicator {
  height: 40px;
  width: 85px;
  border-radius: 4px;
  background: #ffffff;
  border: 1px solid #e9e9e9;
  position: absolute;
  top: 0px;
  left: 0px;
}

.wallet_amount .custom_radio input:checked + .radio_indicator {
  border-color: #7100fa;
}

.wallet_amount .custom_radio input:checked + .radio_indicator + .radio_text {
  color: #7100fa;
}

.wallet_amount .radio_text {
  z-index: 1;
  font-size: 16px;
  color: #000c38;
  position: absolute;
  display: block;
  text-align: center;
  padding: 9px 5px;
  top: 0;
  width: 100%;
}

.wallet_amount {
  max-width: 370px;
  width: 100%;
}

.wallet_amount.activeColor {
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.wallet_amount .form-control {
  width: 238px;
  height: 55px;
}

.card_list .custom_radio {
  width: 100%;
  display: block;
  cursor: pointer;
  line-height: 50px;
  position: relative;
  max-width: 276px;
}

.card_list .custom_radio input {
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  margin: 0px 0px;
  z-index: 2;
}

.card_list .custom_radio .radio_indicator {
  height: 50px;
  width: 276px;
  border-radius: 8px;
  background: #f5f5f5;
  border: 0;
  position: absolute;
  top: 0px;
  left: 0px;
}

.card_list .custom_radio input:checked + .radio_indicator:before {
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background-color: #7100fa;
  position: absolute;
  right: 19px;
  top: 16px;
  content: '';
}

.card_list .custom_radio input + .radio_indicator:after {
  width: 25px;
  height: 25px;
  border: 1px solid #7d36ff;
  border-radius: 100%;
  position: absolute;
  right: 15px;
  top: 12px;
  content: '';
}

.card_list .radio_text {
  z-index: 1;
  font-size: 13px;
  color: #000000;
  position: absolute;
  display: flex;
  padding: 1px 15px;
  top: 0;
  width: 100%;
  align-items: center;
}

.add_card .accordion-header button {
  background: none !important;
  border: 0;
  padding-left: 0;
  padding-right: 0;
  font-size: 18px;
  color: #000000;
  font-weight: 500;
  box-shadow: none !important;
}

.add_card .accordion-item {
  border-top: 0 !important;
  border-bottom: 1px solid #e3e3e3 !important;
  border-left: 0 !important;
  border-right: 0 !important;
}

.swift_modal .modal-title {
  font-size: 22px;
}

.sidebar {
  background-image: linear-gradient(#6236ff, #9b36ff);
  padding: 28px 0;
  overflow-y: auto;
}

.sidebar.offcanvas.offcanvas-start.show {
  max-width: 300px;
}

.sidebar .profile img {
  width: 80px;
  height: 80px;
  border-radius: 100%;
}

.sidebar .profile {
  background-color: #7f54ff;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 104px;
  height: 104px;
  margin: auto;
}

.sidebar h4 {
  font-size: 14px;
  font-weight: 300;
  padding: 10px;
  margin: 0;
}

.sidebar h4 span {
  font-size: 18px;
  font-weight: 400;
  padding: 6px;
}

.sidebar a {
  text-decoration: none;
}

/* .sidebar_main {
  max-height: 700px;
  overflow-y: scroll;
} */

.cashback {
  font-size: 12px;
  color: #241b1b;
  background-color: #f9f9f9;
  margin-bottom: 0;
  padding: 4px 8px;
  margin-left: auto;
}

.sidebar a:hover .cashback {
  background-color: #6436ff;
  color: #fff;
}

.sidebar .sidebar_main a:hover {
  /* background-color: #fff; */
  color: #6436ff !important;
  background-color: #e9ecef !important;
}

.sidebar .sidebar_main a:hover img {
  filter: brightness(0) saturate(100%) invert(18%) sepia(53%) saturate(6704%)
    hue-rotate(252deg) brightness(108%) contrast(100%);
}

.scrollbar::-webkit-scrollbar {
  width: 5px;
  background-color: #fff;
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: #6436ff;
}

.refer_earn .promo_card h3 {
  font-size: 15px;
}

.refer_earn .promo_card .refer_img {
  width: 174px;
  height: 174px;
  border-radius: 100%;
  margin-bottom: 35px;
}

.refer_earn .promo_card h5 {
  font-size: 16px;
  color: #56565b;
  font-weight: 300;
}

.refer_earn .promo_card h5 span {
  font-size: 28px;
  color: #101013;
  font-weight: 700;
  margin: 8px 0 32px;
}

.copy_btn {
  border: 2px solid #6436ff;
}

.copy_btn:hover {
  background: #6436ff;
  color: #fff !important;
  border: 2px solid #6436ff;
  background-color: #6236ff !important;
}

.refer_earn .promo_card h4 {
  font-size: 18px;
  color: #111113;
  font-weight: 500;
  margin-bottom: 20px;
}

.refer_earn .main_panel p {
  font-size: 16px;
  color: #111113;
  font-weight: 400;
}

.reward_pt .promo_card h3 {
  font-size: 15px;
}

.reward_pt .promo_card .expire_date {
  font-size: 15px;
  color: #707070;
}

.refer_box {
  background: url('../images/reward_shape.png') no-repeat right top #f2f0fc;
}

.refer_box h6 span {
  font-size: 25px;
}

.wallet_statement h3 {
  font-size: 20px;
  color: #000;
}

.paid_money {
  border: 1px solid #e5e5e5;
}

.paid_money h4 {
  font-size: 14px;
  color: #000;
}

.paid_money h5 {
  font-size: 13px;
  color: #a5a5a5;
}

.paid_money h6 {
  font-size: 14px;
  color: #6236ff;
  font-weight: 600;
  vertical-align: baseline;
}

.paid_money h6 span {
  font-size: 11px;
  color: #498100;
}

.wallet_statement .text-success,
.wallet_statement .text-danger {
  font-size: 14px;
  color: #498100;
}

.wallet_statement .failed_text {
  font-size: 11px;
}

.offcanvas-header .btn-close {
  background: #fff url('../images/close-circle.svg') no-repeat;
  background-position: center;
  opacity: 1;
}

.settings h5 {
  font-size: 16px;
  color: #000;
  font-weight: 600;
}

.settings .form-check-input:checked {
  background-color: #6436ff;
}

.settings .form-check-input:focus {
  box-shadow: none;
  /* border: 0; */
}

.fullPage_space {
  padding: 0 40px;
}

.sidebar_pageHeading h5 {
  font-weight: 400;
  font-size: 16px;
}

.loan_status .nav-tabs .nav-link {
  border: 0;
  border-bottom: 1px solid transparent;
  color: #000000;
  font-size: 16px;
}

.loan_status .nav-tabs .nav-link.active,
.loan_status .nav-tabs .nav-link:focus,
.loan_status .nav-tabs .nav-link:active {
  border: 0;
  border-bottom: 1px solid #6436ff;
  color: #6436ff;
  background: none;
}

.loan_status .accordion-button.collapsed {
  background-color: #fff;
}

.loan_status .accordion-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f6f3ff;
  box-shadow: none;
  padding: 3px 0px;
}

.fw-bold {
  font-weight: 500 !important;
}

.loan_status .accordion-button::after {
  position: absolute;
  right: 20px;
}

.loan_status_box li span {
  color: #979595;
}

.loan_status_box li {
  border-radius: 8px;
  background: #f6f3ff;
  padding: 15px;
  max-width: 175px;
  width: 100%;
  margin: 0 5px 10px;
}

.loan_status .accordion-button span.text-dark.mb-0 {
  width: 200px;
}

.installment_number p {
  color: #000000;
  font-size: 15px;
}

.installment_number p span {
  font-weight: 500;
  font-size: 25px;
}

.installment_canvas .card_list .custom_radio .radio_indicator,
.installment_canvas .card_list .custom_radio {
  max-width: 313px;
  width: 100%;
  height: 85px;
  background: #faf9fe;
  border-radius: 8px;
}

.installment_canvas .card_list .radio_text {
  line-height: 24px;
  font-size: 15px;
  top: 17px;
}

.installment_canvas .card_list .custom_radio input + .radio_indicator:after {
  top: 30px;
}

.installment_canvas
  .card_list
  .custom_radio
  input:checked
  + .radio_indicator:before {
  top: 34px;
}

.card_name {
  background-color: #000;
  margin-bottom: 15px;
  border-radius: 10px;
}

.bankList .custom_radio {
  display: inline-block;
  cursor: pointer;
  line-height: 22px;
  position: relative;
}

.bankList .custom_radio input {
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  margin: 0px 0px;
  z-index: 2;
}

.bankList .custom_radio .radio_indicator {
  height: 74px;
  width: 74px;
  border-radius: 50%;
  background: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 10px -2px rgba(0, 0, 0, 0.1) inset;
  margin: 0 auto;
  border: 1px solid transparent;
}

.bankList li {
  width: calc(33.33% - 20px);
  text-align: center;
  margin: 0 10px 20px;
}

.bankList li .text {
  font-size: 13px;
  color: #000000;
}

.bankList .custom_radio input:checked + .radio_indicator {
  border: 1px solid #6236ff;
}

.bankList .custom_radio .radio_indicator img {
  filter: grayscale(100%);
  opacity: 0.6;
}

.bankList .custom_radio input:checked + .radio_indicator img {
  filter: grayscale(0);
  opacity: 1;
}

.bankList .custom_radio input:checked + .radio_indicator + .text {
  color: #6236ff;
}

.profile form {
  max-width: 515px;
  margin: 0 auto;
}

.profile form .profile_img {
  max-width: 112px;
  margin: 0 auto 10px;
}

.profile form .profile_pic .profile_img > img {
  width: 112px;
  height: 112px;
  border-radius: 100%;
}

.profile form .profile_pic input {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9;
}

.profile form .profile_pic .change_icon {
  bottom: 0;
  right: 0;
}

.profile form .profile_pic h6 {
  color: #498100;
  font-size: 14px;
  font-weight: 400;
}

form h4 {
  font-size: 20px;
}

.order_list a:hover {
  border-color: #6236ff !important;
  color: #6236ff;
}

.order_list button.active {
  border-color: #6236ff !important;
  color: #6236ff;
}
.order_list a {
  color: #000;
}

.text-sm {
  font-size: 13px;
}

.recent_order .nav-tabs .nav-link {
  color: #000;
  font-weight: 500;
  border: 0;
  box-shadow: none;
  border-bottom: 1px solid transparent;
}

.recent_order .nav-tabs .nav-link.active {
  border-bottom: 1px solid #6236ff;
  color: #6236ff;
}

.text-grey {
  color: #a0a0a0;
}

.contact_info .theme-border {
  width: 24px;
  height: 24px;
}

.default_checkbox .custom_check {
  position: relative;
  cursor: pointer;
  line-height: 22px;
}

.default_checkbox .custom_check input {
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  margin: 0px 0px;
  z-index: 2;
}

.default_checkbox .custom_check .check_indicator {
  height: 22px;
  width: 22px;
  position: absolute;
  top: 0px;
  left: 0px;
  background: #ffffff;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.default_checkbox .custom_check input:checked + .check_indicator:before {
  width: 7px;
  height: 12px;
  position: absolute;
  left: 6px;
  top: 2px;
  border: solid #6236ff;
  border-width: 0px 2px 2px 0px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  content: '';
}

.default_checkbox .custom_check input:checked + .check_indicator {
  border-color: #6236ff;
}

.location_locate {
  max-width: 290px;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  background: #fff;
}

.location_locate img {
  max-width: 50px;
  max-height: 50px;
}

img {
  max-width: 100%;
}

.filter_icon {
  width: 66px;
  height: 66px;
  position: absolute;
  top: 20px;
  right: 20px;
}

.card_balance {
  background-color: #63c3eb;
  border-radius: 8px;
}

.card_balance h5 {
  font-size: 21px;
  font-weight: 400;
}

.card_balance h5 span {
  font-size: 30px;
  font-weight: 500;
}

.sendgift h4 {
  font-size: 18px;
}

.event_select .custom_radio {
  display: inline-block;
  cursor: pointer;
  line-height: 22px;
  position: relative;
}

.event_select .custom_radio input {
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  margin: 0px 0px;
  z-index: 2;
}

.event_select .custom_radio input:checked + .radio_indicator {
  background: url('../images/event_check.svg') no-repeat;
  position: absolute;
  right: 0px;
  top: 0;
  content: '';
  width: 22px;
  height: 22px;
}

.event_select + .event_select {
  margin-left: 15px;
}

.event_select h6 {
  margin-top: 10px;
}

.promo_input .form-floating {
  max-width: 600px;
}

.navbar-collapse {
  justify-content: flex-end;
}

.header {
  position: fixed;
  left: 0;
  padding: 20px 0;
  top: 0;
  right: 0;
  z-index: 99;
  background-color: #f8fbfe;
}

.header .nav-link {
  color: #000000;
  font-size: 16px;
  font-weight: 500;
}

.header .nav-link:hover {
  color: #6936ff;
}

.header .nav-link.btn:hover {
  color: #fff;
}

.banner-content-row {
  display: flex;
  align-items: center;
}

.header .navbar-nav .nav-link + .nav-link {
  margin-left: 30px;
}

.navbar-nav {
  margin-right: 39px;
}

.banner-caption {
  color: #000;
  flex: 0 0 55%;
  max-width: 55%;
  padding-right: 20px;
}

header .btn {
  padding: 6px 11px;
  font-size: 14px;
  font-weight: 400;
  border-radius: 8px;
}

.btn {
  padding: 15px 20px;
  font-size: 16px;
  font-weight: 400;
  text-shadow: none;
  color: #fff;
  background-color: #6736ff;
}

.btn.nav-link {
  color: #fff;
}

.btn:hover {
  background-color: #420ae9;
}

.btn-outline-primary:hover {
  background-color: #fff;
  color: #000 !important;
}

.banner-caption p {
  color: #4b5a69;
  font-size: 15px;
  line-height: 1.7;
  padding: 10px 0 10px 0;
  margin-bottom: 10px;
}

.banner-caption h1 {
  font-weight: 600;
  font-size: 60px;
}

.download-link strong {
  display: block;
  color: #4b5a69;
  margin-bottom: 15px;
  font-size: 16px;
}

.download-link a {
  margin-right: 10px;
}

.banner-main-img {
  flex: 0 0 45%;
  max-width: 45%;
  text-align: right;
}

.banner-part {
  background: url(../images/banner_bg.png) no-repeat left bottom #f8fbfe;
  position: relative;
  z-index: 1;
  padding-top: 150px;
  padding-bottom: 0;
  padding-bottom: 15px;
}

.banner-part .container {
  position: relative;
  z-index: 2;
}

.privatePolicy-part {
  background: #f8fbfe;
  position: relative;
  z-index: 1;
  padding-top: 150px;
  padding-bottom: 0;
  padding-bottom: 15px;
}

.privatePolicy_banner {
  width: 100%;
  height: 30rem;
  background: url(../images/private.jpg) no-repeat left bottom;
  object-fit: cover;
  position: relative;
  background-attachment: fixed;
}

.privatePolicy_banner h2 {
  font-size: 2rem;
  color: #fff;
  z-index: 2;
}

.section-title {
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 40px;
}

.section-title span {
  font-size: 16px;
  color: #6236ff;
  margin-top: 10px;
}

.crytech-feature {
  background: url(../images/app_screen_bg.jpg) no-repeat left center #f8fbff;
  padding: 50px 0 40px 0;
}

.feature-points {
  padding-left: 54px;
  position: relative;
  padding-top: 10px;
}

.feature-points ul {
  position: relative;
  margin-left: 20px;
  margin-top: 60px;
}

.feature-points ul:before {
  position: absolute;
  left: 8px;
  top: 0;
  height: 100%;
  width: 1px;
  background: #411dbf;
  content: '';
}

.feature-points li {
  margin-bottom: 48px;
  padding-left: 30px;
  position: relative;
  z-index: 2;
  counter-increment: kk;
  list-style: none;
}

.feature-points li h5 {
  font-size: 18px;
  padding: 0 0 0px 0;
  color: #4b5a69;
}

.feature-points li p {
  font-size: 15px;
  color: #767676;
  line-height: 1.3;
}

.feature-points li:before {
  content: counter(kk);
  background: #6936ff;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: 500;
  border-radius: 100%;
  height: 57px;
  width: 57px;
  position: absolute;
  left: -53px;
  top: 50%;
  transform: translateY(-50%);
  outline: 7px double #6936ff;
}

.feature-img {
  padding-right: 25px;
}
.app-showcase {
  background-color: #f8fbff;
  padding: 50px 0 0px 0;
  position: relative;
}

.aboutus-sec {
  background: #f8fbff;
}

.app-showcase:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 77px;
  background-color: #6236ff;
  bottom: 20px;
  left: 0;
  z-index: 0;
}

.app-showcase img {
  z-index: 2;
  position: relative;
}

.footer-content {
  background: #6236ff;
  padding: 60px 0 50px 0;
}

.footer-about p {
  color: #fff;
  line-height: 1.6;
  padding: 20px 0 0 0;
  font-size: 15px;
}

.footer-about {
  padding-right: 10px;
}

.footer-content h5 {
  color: #fff;
  font-size: 16px;
  padding: 0 0 12px 0;
}

.short-link li a {
  color: #fff;
  text-decoration: none;
  padding: 5px 0;
  display: inline-block;
  font-size: 14px;
}

.footer-sociallink a {
  display: inline-flex;
  text-decoration: none;
  border: 1px solid #fff;
  width: 36px;
  height: 36px;
  color: #fff;
  padding: 5px;
  align-items: center;
  justify-content: center;
  margin: 2px;
  font-size: 16px;
}

.copyright {
  padding: 20px 0;
  text-align: center;
  background-color: #411dbf;
  color: #fff;
  font-size: 12px;
}

.subscription-section .row {
  align-items: center;
  justify-content: space-between;
}

.subscribe-form .form-control {
  background: transparent;
  border: none;
  color: #fff;
}

.subscribe-form .form-control:focus {
  box-shadow: none;
}

.subscribe-form .form-control::placeholder {
  color: #fff;
}

.subscribe-form .btn {
  padding: 10px 20px;
  background: rgb(239, 120, 82);
  background: linear-gradient(
    0deg,
    rgba(239, 120, 82, 1) 0%,
    rgba(234, 75, 125, 1) 50%,
    rgba(214, 96, 191, 1) 100%
  );
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
  font-family: 'sf_ui_displaybold', sans-serif;
  border-radius: 6px;
}

.subscribe-form .form-control::placeholder {
  color: #9c9c9c;
}

.subscribe-title h3 strong {
  display: block;
  font-size: 37px;
  font-family: 'sf_ui_displaysemibold', sans-serif;
  color: #fff;
  line-height: 1.5;
}

.subscribe-title h3 small {
  font-size: 16px;
  color: #fff;
}

.aboutus-sec .count-ul {
  background-image: -moz-linear-gradient(
    -90deg,
    rgb(214, 96, 191) 0%,
    rgb(234, 75, 125) 51%,
    rgb(239, 120, 82) 100%
  );
  background-image: -webkit-linear-gradient(
    -90deg,
    rgb(214, 96, 191) 0%,
    rgb(234, 75, 125) 51%,
    rgb(239, 120, 82) 100%
  );
  background-image: -ms-linear-gradient(
    -90deg,
    rgb(214, 96, 191) 0%,
    rgb(234, 75, 125) 51%,
    rgb(239, 120, 82) 100%
  );
  padding: 52px 28px 46px 9px;
  border-radius: 10px;
}

.about-content p:last-child {
  margin-bottom: 0px;
}

.about-content p {
  color: #767676;
  font-size: 16px;
  font-weight: 100;
  line-height: 22px;
  margin-bottom: 31px;
}

.about-content {
  padding: 36px 37px 0px 37px;
}

/* faq-sec */

.faq-sec {
  padding: 10px 0 60px;
  background: #f8fbff;
  margin-top: 89px;
}

.faq-sec .accordion-button:focus {
  box-shadow: none;
}

.faq-sec .accordion-body p {
  font-size: 16px;
  color: #767676;
  line-height: 23px;
  margin-bottom: 26px;
}

.faq-sec .accordion-body {
  padding: 20px 16px 23px 21px;
  color: #4b5a69;
  font-size: 16px;
  background-color: #f1f4f6;
}

.faq-sec .accordion-body p:last-child {
  margin-bottom: 0px;
}

.faq-sec .accordion .accordion-button.collapsed {
  font-size: 18px;
  color: #4b5a69;
  padding: 15px 20px 15px 20px;
  border-radius: 6px;
  background-color: #f1f4f6;
}

.faq-sec .accordion .accordion-item {
  margin-bottom: 5px;
  border-radius: 6px;
  overflow: hidden;
  border: 0;
}

/*
.accordion-button.collapsed::after {
    background: url("../images/arrow.png") no-repeat center;
    transform: rotate(0);
}*/

/*
.accordion-button:not(.collapsed) {
  background-color: #6736ff;
  color: #fff;
  font-size: 18px;
  transform: rotate(0);
}
*/
/*
.accordion-button:not(.collapsed)::after {
    background: url("../images/active_arrow.png");
    transform: rotate(0);
}*/

.footer-sociallink a img {
  width: 15px;
  height: 18px;
}

.footer-sociallink a:hover {
  background-color: #411dbf;
}

.short-link li:hover a {
  color: #c7c7c7;
}

.wallet_statement .btn {
  padding: 15px 32px;
}

.sendgift .copy_btn {
  color: #6736ff;
}

.alert-success {
  position: fixed;
  top: 0px;
  right: 0px;
  border-radius: 0px;
  z-index: 1056;
}

.alert-danger {
  position: fixed;
  top: 0px;
  right: 0px;
  border-radius: 0px;
  z-index: 1056;
}

.header_profile .dropdown-toggle img {
  max-width: 30px;
  border-radius: 1090%;
  max-height: 30px;
  object-fit: cover;
}
.set-pin-sec input[type='tel'] {
  margin: 0px 7px;
  box-shadow: none;
  border-color: #0000002b;
  width: 100% !important;
  height: 40px;
  border-radius: 6px;
}

.set-pin-sec input[type='tel']:first-child {
  margin-left: 0px;
}

.register-form-sec .dropdown-menu {
  min-width: auto;
  width: 100%;
}

.mobile-form-sec .dropdown-menu {
  min-width: auto;
  width: 100%;
}

.mobile-form-sec .btn:first-child {
  width: 96%;
}

.slipDownButton:active {
  transform: translateX(1px);
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* react select styles */
/* .css-181qgi4-control{
  height: 58px;
} */
/* .select__control.css-181qgi4-control{
  min-height: 58px;
  min-width:150px
}
.select__control.css-13cymwt-control{
  min-height: 58px;
  min-width:150px

} */

header,
.main_wrap {
  transition: 0.4s ease-in-out;
}

.toggleSidebar header,
.toggleSidebar .main_wrap {
  margin-left: 300px;
}

.toggleSidebar .offcanvas-backdrop {
  display: none;
}

.toggleSidebar .main_wrap {
  padding: 0 50px;
}

form.mobile-form-sec .dropdown .dropdown-toggle,
.form-floating .form-control,
.form-floating select {
  border-radius: 12px;
}

.form-floating label,
.custom_check {
  font-size: 18px;
}

form.mobile-form-sec .dropdown .dropdown-toggle {
  border: 1px solid #ced4da;
}

.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.65) translateY(-0.5rem) translateX(0.5rem);
}

.active > .page-link,
.page-link.active {
  background: #6a39fe;
  border-color: #6a39fe;
}

/* .toggle_body .App {
  margin-left: 286px;
  transition: .4s;
} */

.sidebar a.active {
  background: #fff;
  color: #6436ff !important;
}

.sidebar a.active img {
  filter: brightness(0) saturate(100%) invert(18%) sepia(53%) saturate(6704%)
    hue-rotate(252deg) brightness(108%) contrast(100%);
}

.sidebar a.active .cashback {
  background-color: #6436ff;
  color: #ffff;
}

.track_modal-content div,
.track_modal-content h6 {
  max-width: 33.33%;
  width: calc(100% - 21px);
  margin-right: 25px;
}

.track_modal-content h6 span {
  word-break: break-all;
}

.ahref {
  color: rgba(0, 0, 0, 0.55);
  text-decoration: none;
}

.sidebar_off {
  border: transparent;
  background: transparent;
  position: absolute;
  padding: 10px;
  right: 5px;
  top: 5px;
}

.sidebar_off svg {
  color: #fff;
  width: 24px;
  height: 24px;
}

.dps_fix {
  flex: 0 0 25%;
  max-width: 25%;
}

.dps_fix_three {
  flex: 0 0 21%;
  max-width: 21%;
}
.dps_fix_three.dps_first_sec {
  flex: 0 0 40%;
  max-width: 40%;
}
.form-check-input {
  margin-right: 10px;
}

.personal_info_form .form-control {
  padding: 22px 14px;
}

.upload-third .position-relative {
  width: 100%;
  max-width: 194px;
}

.personal_info_form .custom_check {
  font-size: 15px;
}
/*Chrome*/
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type='range'] {
    overflow: hidden;
    width: 180px;
    -webkit-appearance: none;
    background-color: #dadada;
    border-radius: 4px;
  }

  input[type='range']::-webkit-slider-runnable-track {
    height: 10px;
    -webkit-appearance: none;
    color: #6104f7;
    margin-top: -1px;
  }

  input[type='range']::-webkit-slider-thumb {
    width: 5px;
    -webkit-appearance: none;
    height: 10px;
    cursor: ew-resize;
    background: #6104f7;
    box-shadow: -80px 0 0 80px #6104f7;
  }
}

/** FF*/
/* input[type="range"]::-moz-range-progress {
background-color: #43e5f7;
}
input[type="range"]::-moz-range-track {
background-color: #9a905d;
} */
/* IE*/
/* input[type="range"]::-ms-fill-lower {
background-color: #43e5f7;
}
input[type="range"]::-ms-fill-upper {
background-color: #9a905d;
} */

.css-4wwese-Slider {
  width: 100% !important;
}

/* .special-btn.btn:hover {
    background-color: red!important;
}
.pay-btn.btn:hover {
    background-color: green!important;
} */

.valid-msg .text-danger {
  color: green !important;
}

.toggleSidebar {
  overflow: visible !important;
}

.transac-icon {
  width: 1.2rem;
  height: 1.2rem;
  filter: brightness(0) invert(1);
}

.sidebar .sidebar_main a:hover .transac-icon,
.sidebar a.active .transac-icon {
  filter: none;
}

.sidebar_main span {
  min-width: 24px;
}

.sidebar_main span img {
  width: 20px;
  height: 20px;
}

.td-border-none td,
.td-border-none th {
  border: none;
}

.td-border-none {
  border: 1px solid #ddd;
}

.pagger_outer .pagination {
  justify-content: flex-end;
}

.get_loan h4 {
  font-size: 24px;
}

.loan-status-header span:last-child {
  padding-right: 62px;
}

.first_cols {
  flex: 0 0 36%;
  max-width: 36%;
}

.second_cols,
.third_cols,
.fourth_cols {
  flex: 0 0 21.333%;
  max-width: 21.333%;
}

.first_cols figcaption {
  flex: 1;
  padding-right: 60px;
}

.first_cols figure {
  flex: 0 0 42px;
  max-width: 42px;
}

.bank_list {
  border: 1px solid #e5e5e5;
  position: relative;
}

.bank_list_left figure {
  width: 44px;
  height: 44px;
  background: #efefef;
  border-radius: 100px;
  padding: 2px;
}

.bank_list_left figure img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 100px;
}

.bank_list_left {
  width: 100%;
}

.bank_list input[type='radio'] {
  top: 0;
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
  opacity: 0;
}

.bank_detail li {
  font-size: 16px;
  padding: 10px 0;
}

.totel_amount {
  font-size: 18px;
  border-top: 1px solid #ddd;
  padding-top: 24px;
  margin: 24px 0;
}

.bank_list .custom_radio {
  border: 1px solid #ddd;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50px;
  position: relative;
  top: 6px;
}

.bank_list .custom_radio:after {
  background: #8947fe;
  width: 14px;
  height: 14px;
  content: '';
  position: absolute;
  border-radius: 50px;
  margin: 2px;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transform: scale(0);
}

.bank_list input:checked + .custom_radio {
  border-color: #6736ff;
}

.bank_list input:checked + .custom_radio:after {
  transform: scale(1);
}

.common-height {
  min-height: calc(100% - 128px);
}

.bg-success:hover {
  background-color: #198754 !important;
}

.installment-sec .btn {
  padding: 6px 20px;
  margin-bottom: 17px;
}

.dps_third-sec {
  width: 27%;
}

.btn-paid {
  background-color: #28a745;
  color: #fff;
  padding: 6px 20px;
  margin-bottom: 17px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 400;
  text-shadow: none;
}

/* 14/4/21 */

.common-table tr th {
  background: #e1e6ee;
  padding: 12px 15px;
}
/* 14/4/21 */

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: #fff;
  color: var(--bs-table-striped-color);
}

.common-table tr td {
  border-right: 1px solid #00000014;
  padding: 19px 19px;
}

.common-table {
  border: 1px solid #aa9a9a29;
  border-radius: 11px;
}

.country-label span {
  position: absolute;
  top: 5px;
  font-size: 11px;
  text-align: center;
  left: 50;
  width: 100%;
  opacity: 0.65;
}

.country-label select {
  padding-top: 9px !important;
}
.text_right {
  text-align: right;
}
.text_center {
  text-align: center;
}

/* input type file */
.image_title {
  position: absolute;
  top: 8px;
  left: 120px;
}

.login_tab_content {
  display: flex;
  width: 100%;
}

.register form.individual_business {
  max-width: 100%;
}

.register-form-sec.individual_business .tabs_lg {
  padding: 24px;
  border-radius: 10px;
  border: 1px solid #dddd;
  font-size: 20px;
  font-weight: 500;
  background: #f1f1f1;
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.register_wrap {
  min-height: 750px;
  margin: 44px 0;
  position: relative;
}

.registration_form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 36px;
  border-top: 1px solid #ddd;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.registration_form input[type='checkbox'] {
  width: 20px;
  height: 20px;
  vertical-align: middle;
  margin-right: 8px;
  margin-top: -2px;
}

.tearm_of_use {
  font-size: 16px;
  color: #3f3f3f;
}

.tearm_of_use a {
  text-decoration: none;
  color: #6736ff;
}

.registration_form .btn.btn-primary {
  padding: 10px 20px;
  min-width: 200px;
  border: none;
}

.register_wrap h3 {
  padding: 24px 36px;
  border-bottom: 1px solid #ddd;
  font-size: 24px;
}

.register_wrap_tabs {
  padding: 24px 36px 100px 36px;
}
.register_wrap .nav-link {
  border: 1px solid #ddd;
  font-size: 18px;
  padding: 10px 30px 10px 40px;
  margin: 0 10px;
  border-radius: 8px;
  color: #898989;
  position: relative;
}

.register_wrap .nav-tabs {
  border: none;
  padding: 0 0 15px 0;
}

.register_wrap .nav-link.active {
  background: #6236ff;
  border: 1px solid #6236ff;
  color: #fff;
}

.register_wrap .nav-link:after {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  border: 1px solid #ddd;
  left: 10px;
  border-radius: 100%;
  top: 13px;
}

.register_wrap .nav-link:before {
  content: '';
  background: #492c2c;
  width: 12px;
  height: 12px;
  position: absolute;
  left: 14px;
  border-radius: 100px;
  top: 17px;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transform: scale(0);
}

.register_wrap .nav-link.active:before {
  transform: scale(1);
  background: #fff;
}
.upload_picture {
  background: #f2f2f2;
  border-radius: 12px;
  padding: 16px;
  display: flex;
  align-items: center;
  position: relative;
}

.upload_picture input[type='file'] {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
}

.uploaded {
  position: relative;
}

.uploaded figure {
  width: 74px;
  height: 74px;
  border-radius: 6px;
  overflow: hidden;
  margin: 0;
}

.uploaded figure img {
  width: 100%;
  height: 100%;
}

.close_file {
  position: absolute;
  right: -5px;
  bottom: -3px;
}

.close_file img {
  width: 26px;
  cursor: pointer;
}

.upload_picture span {
  padding-left: 10px;
  font-size: 18px;
}

.Upload_identity_card {
  padding: 36px 0 0 0;
}

.Upload_identity_card h4 {
  margin: 0;
  font-size: 20px;
}

.upload_card_field_wrap,
.upload_field_wrap {
  flex: 0 0 50%;
  max-width: 50%;
}

.upload_field_wrap {
  padding: 10px;
}

.upload_card_field {
  background: #f2f2f2;
  border-radius: 12px;
  width: 100%;
  height: 98px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.upload_card_field input[type='file'] {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  opacity: 0;
}

.upload_file_row {
  display: flex;
  text-align: center;
  margin: 0 -10px;
}

.upload_file_row h6 {
  margin: 15px 0 0 0;
  font-size: 16px;
}

.upload_card_field_wrap {
  padding: 10px;
}

.upload_card {
  position: relative;
  height: 99px;
  overflow: hidden;
  border-radius: 10px;
}
.upload_card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.upload_card .upload_card_crose {
  position: absolute;
  top: 5px;
  right: 6px;
}

.login_register_link {
  font-size: 16px;
  text-decoration: none;
  color: #747474;
  padding: 0 10px;
  font-weight: 500;
}

.login_register_link:hover {
  color: #6236ff;
}

.form-select-special {
  height: 58px;
}

.red-btn:hover,
.red-btn {
  background: red !important;
}

.email_small_head {
  font-size: 14px;
  font-weight: normal;
}

.email_detail .modal-content {
  min-width: inherit;
  max-width: 380px;
}

.email_grp label {
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
  font-size: 16px;
}

.email_grp label input[type='checkbox'] {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  position: relative;
  top: 2px;
}
.email_form {
  text-align: left;
}
.email_grp .email_crose {
  position: absolute;
  right: 0;
  top: 0;
}

.email_form .btn.btn-primary {
  margin-top: 0 !important;
  width: 100%;
  font-size: 18px !important;
}
.country_code {
  border: 1px solid #ced4da;
  font-size: 16px;
  border-radius: 6px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 16px;
}
.email_detail .modal-header .btn-close {
  position: absolute;
  right: 16px;
  background-size: 30px !important;
  top: 16px;
}

.email_grp .email_crose .btn-close {
  background: #fff url('../images/close-circle.svg') no-repeat;
  background-position: center;
  opacity: 1;
}

.plushcricle {
  display: inline-flex;
  width: 24px;
  height: 24px;
  background: #eee;
  align-items: center;
  justify-content: center;
  font-size: 21px;
  border-radius: 100px;
  margin-right: 6px;
  top: 3px;
  position: relative;
  margin-bottom: 10px;
}

.email_form .form-label {
  font-weight: 500;
}

.dev-remove-modal {
  width: 147px;
}
.address-dash-circle {
  cursor: pointer;
  color: red;
}
.language img {
  width: 26px;
  height: 26px;
  border-radius: 100%;
}

/* 9/8/23 */

.change-password-panel .form-control {
  padding-right: 49px !important;
}
.change-password-panel .password_view {
  top: 50%;
  display: block;
  transform: translateY(-50%);
  bottom: 0;
}

.change-password-panel .password_view {
  top: 50%;
  display: block;
  transform: translateY(-70%);
  bottom: 0;
  position: absolute;
  right: 20px;
  font-size: 25px;
  /* bottom: 0; */
  cursor: pointer;
}

.forget-form .form-select {
  padding: 16px 11px;
}

/* 9/8/23 */

::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.tran-cater {
  overflow: hidden;
  /* text-overflow: ellipsis; */
  white-space: nowrap;
  max-width: 224px;
}


/* .main_wrap{
  overflow-y: auto;
} */
iframe{
  right: 0px!important;
  bottom: 50px!important;
}

#loader{
  z-index: 100;
}