@media screen and (max-width: 1400px) {
  .gift_form .tabs_theme .btn_theme {
    padding: 13px 50px !important;
  }
  .copy_tag {
    left: -65px;
    max-width: 67px;
  }
  .plans_data table {
    width: 760px;
  }
  .tabs_theme td {
    padding: 10px;
    font-size: 14px;
  }
  .tabs_theme .btn_theme {
    font-size: 14px;
    padding: 8px 15px;
  }
  .tabs_theme .nav-link {
    margin: 0 0px 10px 0px;
    padding: 0 5px 5px 5px;
    font-size: 14px;
  }

  .promo_card figcaption {
    flex: 1 1;
    padding: 5px 0 10px 10px;
  }
  .gas_provider_list {
    padding: 14px 0px;
  }
  .get_loan h6 {
    font-size: 26px;
  }
}
@media screen and (max-width: 1199px) {
  .toggleSidebar header,
  .toggleSidebar .main_wrap {
    margin-left: 0px;
  }
  form.mobile-form-sec .dropdown .dropdown-toggle {
    padding: 0px 6px;
}
  .form-floating label,
  .custom_check {
    font-size: 16px;
  }
  .personal_info_form h6 {
    font-size: 14px;
  }

  .toggleSidebar .main_wrap {
    padding: 0px 50px;
  }
  .order_list > a {
    margin-bottom: 10px;
  }
  .main_wrap {
    padding: 0 50px;
  }
  .tabs_theme td {
    padding: 10px;
    font-size: 14px;
  }
  .btn_theme {
    font-size: 14px;
    padding: 8px 15px;
  }
  .get_loan h6 {
    font-size: 20px;
    white-space: nowrap;
  }
  .request_type form {
    padding: 20px 50px;
  }
  .avail h5 span {
    font-size: 25px;
  }
  .login_right h5 {
    font-size: 16px;
  }
  .wallet .avail {
    max-width: 318px;
  }
  .avail {
    padding: 28px 10px;
  }
  .footer p {
    font-size: 14px;
  }
  .footer ul li a {
    padding: 0 5px;
    font-size: 14px;
  }
  .right_inner_wrap .nav-item .nav-link {
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 991px) {
  .toggleSidebar .main_wrap {
    padding: 0px 30px;
  }

  .invoice-table table {
    width: 923px;
}

.invoice-table {
    overflow-x: auto;
}

  .first_cols {
    flex: 0 0 26%;
    max-width: 26%;
  }

  .loan-status-header span:last-child {
    font-size: 14px;
  }
  .register_wrap {
    min-height: auto;
  }
  .paid_money h6 {
    margin-top: 7px;
  }
  .location_locate {
    left: 0;
  }
  .copy_tag {
    left: auto;
    right: 0;
  }
  .electricity_bill .right_inner_wrap {
    padding: 0px 32px 32px 10px;
  }
  .request_type form {
    padding: 20px 30px;
  }
  .promo_value_list figcaption .promo-small-title {
    font-size: 16px;
  }
  .left_form_wrap {
    padding: 35px 16px;
  }
  .btn_theme {
    background-color: #6436ff;
    color: #fff;
    font-size: 14px;
    padding: 11px 30px;
    border: 0;
  }
  .tabs_theme .nav-link {
    font-size: 16px;
  }
  .main_wrap {
    padding: 0 30px;
  }
  .register .nav-tabs .nav-link {
    padding: 15px 10px;
  }

  .footer p {
    width: 100%;
    text-align: center;
    font-weight: 600;
  }
  .home_main ul li a figure img {
    width: 90px;
    height: 90px;
    border-radius: 100%;
    transition: 0.4s ease all;
  }
  .home_main ul li {
    padding: 0 15px;
    max-width: 120px;
  }
  .page_heading h2 {
    font-size: 18px;
  }
  .wallet_amount .custom_radio:last-child {
    margin-right: 0;
  }
}
@media screen and (max-width: 768px) {
  /* .loan_status .accordion-header .link {
    display: none;
  } */

  .forget-form .form-select {
    padding: 13px 11px;
  }

  .verify_modal .modal-content {
    min-width: 100%;
  }

  .loan_status .accordion-button {
    background-color: transparent;
  }

  .third_cols,
  .fourth_cols {
    position: absolute;
    flex: 1 1;
    max-width: 100%;
    top: 3px;
  }

  .second_cols {
    top: 11px;
    flex: 1 1;
    max-width: 100%;
    text-align: right;
    padding-right: 38px;
    padding-top: 14px;
  }
  .loan_status .accordion-button::after {
    right: 9px;
    top: 35px;
  }
  .fourth_cols {
    right: 0px;
    flex: 1;
    max-width: 100%;
    top: 3px;
    padding-right: 12px !important;
  }
  .first_cols {
    flex: 1;
    max-width: 100%;
    padding-top: 28px;
  }
  /* .second_cols,
  .third_cols {
    padding-top: 20px;
  } */

  header .btn {
    padding: 6px 6px;
  }
  .country-label select {
    padding-top: 14px !important;
  }
  .form-select-special {
    height: 51px;
  }
  .register_wrap_tabs li {
    margin-bottom: 12px;
  }
  .register_wrap h3 {
    padding: 14px 20px;
    font-size: 18px;
  }
  form.mobile-form-sec .dropdown .dropdown-toggle,
  .form-floating .form-control,
  .form-floating select {
    border-radius: 5px;
  }
  .form-floating > .form-control,
  .form-floating > .form-control-plaintext {
    padding: 10px;
  }
  .form-floating > .form-control,
  .form-floating > .form-control-plaintext,
  .form-floating > .form-select {
    height: calc(3rem + 2px);
    line-height: 1.25;
  }
  .register_wrap_tabs {
    padding: 17px 15px 15px 15px;
  }
  .registration_form {
    padding: 12px 0px;
    flex-direction: column;
    position: initial;
  }
  .tearm_of_use {
    margin-bottom: 16px;
  }
  .language .dropdown button {
    padding: 0;
  }
  .sendgift .wallet_amount {
    margin: 0;
  }
  .get_loan h6 {
    font-size: 30px;
    white-space: nowrap;
  }
  .left_form_wrap {
    padding: 35px 16px 10px;
  }
  .wallet_amount {
    margin: 0 auto;
  }
  .header_right .dropdown-toggle span {
    display: none;
  }
  .avail {
    padding: 28px 10px;
    margin: auto;
  }
  .home_main ul li {
    padding-right: 15px;
    max-width: 165px;
  }
  .footer ul {
    justify-content: center;
  }
  .footer ul li a {
    color: #fff;
    text-decoration: none;
    padding: 0 15px 0 0;
    font-size: 14px;
  }
  .footer p {
    width: 100%;
    text-align: center;
    font-weight: 600;
    margin-bottom: 8px;
  }
}
@media screen and (max-width: 575px) {
  .loan_status .accordion-button {
    font-size: 15px;
  }
  .select-gift-scroller {
    width: 400px;
  }
  .change-password-panel .password_view {
    right: 14px;
    font-size: 22px;
  }

  .tearm_of_use {
    font-size: 14px;
  }
  .form-floating label,
  .custom_check {
    font-size: 14px;
  }
  .Upload_identity_card {
    padding: 23px 0 0 0;
  }
  .upload_file_row h6 {
    font-size: 15px;
  }
  .upload_picture {
    padding: 14px;
  }
  .Upload_identity_card h4 {
    font-size: 17px;
  }
  .upload_picture span img {
    max-width: 17px;
  }
  .upload_picture span {
    padding-left: 10px;
    font-size: 17px;
  }
  .uploaded figure {
    width: 50px;
    height: 50px;
  }
  .register_wrap .nav-link {
    font-size: 16px;
  }
  .form-select {
    padding: 9px 8px;
  }
  .upload-third .position-relative {
    max-width: 100%;
  }
  .download-app img {
    filter: brightness(0) saturate(100%) invert(18%) sepia(53%) saturate(6704%)
      hue-rotate(252deg) brightness(108%) contrast(100%);
  }
  .download-app a {
    color: #6436ff !important;
  }
  .toggleSidebar .main_wrap {
    padding: 0px 20px;
  }
  .filter-sidebar-sec {
    max-width: 320px;
  }
  .refer_earn h3 {
    font-size: 20px;
    margin-bottom: 15px !important;
  }
  .refer_earn h5 {
    font-size: 16px;
  }

  .electricity_bill .right_inner_wrap {
    padding: 0px 15px 15px;
  }
  .get_loan h6 {
    font-size: 26px;
    white-space: nowrap;
  }
  .gas_provider_list li {
    flex: 0 0 26.666%;
    max-width: 100%;
    margin-bottom: 10px;
  }
  .wallet_amount .form-control {
    width: 100%;
    margin-bottom: 15px;
  }
  .main_panel {
    padding: 20px;
    border-radius: 15px;
  }
  .header_left button,
  .header_profile button {
    padding: 0;
  }
  .logo img {
    max-width: 60px;
  }
  .header_right .language {
    display: none;
  }
  .avail {
    max-width: 100%;
  }

  .main_wrap {
    padding: 0 0px;
  }
  .header_profile span {
    display: none;
  }
  .promo_value_list figcaption {
    padding: 0;
  }
  .right_inner_wrap {
    padding: 15px;
  }
  .request_type form {
    padding: 20px 16px;
  }
  /* .loan_status .accordion-header img {
    display: none;
  } */
  .loan_status .accordion-button span.text-dark.mb-0 {
    width: 100px;
  }
}
@media screen and (max-width: 468px) {
  .loan_status .nav-tabs .nav-link {
    font-size: 14px;
    padding: 0;
    margin-right: 10px;
  }
  .second_cols {
    padding-top: 29px;
  }
  .loan_status .accordion-button::after {
    right: 9px;
    top: 42px;
  }
  .fourth_cols {
    top: 6px;
    padding-right: 5px !important;
  }

  .loan_status .accordion-button {
    font-size: 14px;
    white-space: nowrap;
  }
  .loan_status .accordion-button span {
    margin-right: 0;
  }
  .loan_status ol li {
    font-size: 14px;
  }
  .loan-status-header span:last-child {
    padding-right: 14px;
  }

  /* .loan_status .accordion-button{
    flex-wrap: wrap;
  } */

  .tabs_theme .nav-link {
    font-size: 14px;
  }
  .promo_card figcaption h3.pb-0 {
    text-align: left;
  }
  .promo_card {
    display: block;
    text-align: center;
  }
  .promo_card figure {
    margin: 10px auto 10px;
  }
  .get_loan h6 {
    font-size: 26px;
    white-space: pre-wrap;
  }
  .get_loan h6 {
    font-size: 24px;
    white-space: nowrap;
  }
  .page_heading h2 {
    font-size: 18px;
  }
  .breadcrumb {
    padding: 0 15px;
  }
  .footer ul {
    padding: 0 15px;
  }
  .register form {
    padding: 15px;
  }

  .home_main ul li {
    padding: 0 10px;
    max-width: 100%;
    width: 33.33%;
  }
  .home_main ul li a figure img {
    width: 70px;
    height: 70px;
  }
  .home_main ul li span {
    font-size: 14px;
  }
  .tabs_theme .nav-link {
    margin: 0 8px 10px 0px;
  }
  .wallet_amount .custom_radio:last-child {
    margin-top: 10px;
    margin-left: 0;
  }
  .loan_status_box li {
    padding: 9px;
    width: calc(50% - 10px);
  }
  .fullPage_space {
    padding: 0 21px;
  }
  /* .loan_status .accordion-button span:last-child {
    display: none;
  } */
}
@media screen and (max-width: 360px) {
  .register form {
    padding: 10px;
  }

  .forget-form .form-select {
    padding: 13px 8px;
    font-size: 14px;
    background-position: right 5px center;
    background-size: 16px 10px;
  }

  .toggleSidebar .main_wrap {
    padding: 0px;
  }
  .main_wrap {
    padding: 0;
  }
  .main_panel {
    border-radius: 0;
  }
  .home_main ul li {
    max-width: 100%;
  }
}
